@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

:root {
    --font-family: "Open Sans", sans-serif;
    --fw-light: 300;
    --fw-regular: 400;
    --fw-medium: 500;
    --fw-semi-bold: 600;
    --orange-color: #f77e0b;
    --dark-shade-gray: #333333;
    --dim-shade-gray: #707070;
    --dove-shade-gray: #686868;
    --medium-dark-shade-gray: #666666;
    --light-bg: rgba(246, 241, 241, 0.2);
}

body {
    font-family: "Open Sans", sans-serif !important;
    font-family: var(--font-family) !important;
}

/* Font Size */
.fs-9 {
    font-size: 9px;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}

/* Font Weight */
.fw-300 {
    font-weight: 300;
    font-weight: var(--fw-light);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.fw-400 {
    font-weight: 400;
    font-weight: var(--fw-regular);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.fw-500 {
    font-weight: 500;
    font-weight: var(--fw-medium);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.fw-600 {
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

/* Colors */
.color-01 {
    color: #f77e0b;
    color: var(--orange-color);
}

.color-02 {
    color: #333333;
    color: var(--dark-shade-gray);
}

.color-03 {
    color: #707070;
    color: var(--dim-shade-gray);
}

.color-04 {
    color: #686868 !important;
    color: var(--dove-shade-gray) !important;
}

.color-05 {
    color: #666666 !important;
    color: var(--medium-dark-shade-gray) !important;
}

.color-06 {
    color: #000000 !important;
}

.color-07 {
    color: #424242;
}

.color-08 {
    color: #afabab;
}

.light-bg {
    background: rgba(246, 241, 241, 0.2);
    background: var(--light-bg);
    padding: 10px 0;
}

.light--bg {
    background: rgba(246, 241, 241, 0.2);
}

.bg-tr {
    background: rgb(255 255 255);
}

/* Buttons */
.btn-01 {
    background: #f77e0b !important;
    background: var(--orange-color) !important;
    color: #333333;
    color: var(--dark-shade-gray);
    border: 1px solid #4a443b !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    font-weight: var(--fw-semi-bold) !important;
}

.btn-save,
.btn-send {
    background: #009345 !important;
    border: 1px solid #ffffff !important;
    border-radius: 6px !important;
    color: #ffffff !important;
    width: 91px;
    height: 28px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.btn-save:hover,
.btn-send:hover {
    background: #33a96a !important;
}

.updated-save-btn {
    background: #fff;
    border: 1px solid #686868;
    border: 1px solid var(--dove-shade-gray);
    border-radius: 6px;
    color: #686868;
    color: var(--dove-shade-gray);
    width: 91px;
    height: 28px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.updated-save-btn:hover {
    background-color: rgba(255, 255, 255, 0.7);
}

.btn-search {
    background: #ffffff;
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    border-radius: 6px;
    color: #686868;
    color: var(--dove-shade-gray);
    padding: 0 10px;
}

.btn-browse {
    background: #e4e4e4;
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    border-radius: 6px;
    width: 94px;
    height: 32px;
}

.btn-browse:hover {
    background: #cdcdcd;
}

.btn-complete-rca {
    background: #009345 !important;
    border: 1px solid #ffffff !important;
    border-radius: 6px !important;
    color: #ffffff !important;
    /* width: 91px; */
    height: 28px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.btn-complete-rca:hover {
    background: #33a96a !important;
}

/*============= Common Classes CSS ================*/
.form-control {
    border-radius: 0 !important;
}

.form-control:focus {
    color: #686868;
    color: var(--dove-shade-gray);
    background-color: #fff;
    border-color: #cccccc !important;
    outline: 0;
    box-shadow: none !important;
}

.css-1pahdxg-control {
    border-color: #cccccc;
}

.css-1pahdxg-control:hover {
    box-shadow: none !important;
    border-color: #cccccc !important;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none !important;
}

.warning-msg {
    background: #fcdaba;
    padding: 5px 10px;
    font-size: 10px;
    border: 1px solid #686868;
    border: 1px solid var(--dove-shade-gray);
    color: #686868;
    color: var(--dove-shade-gray);
}

.success-msg {
    background: #cde3d0;
    padding: 5px 10px;
    font-size: 10px;
    border: 1px solid #686868;
    border: 1px solid var(--dove-shade-gray);
    color: #686868;
    color: var(--dove-shade-gray);
}

.divider {
    border: 1px solid #bcbcbc;
}

::-webkit-calendar-picker-indicator {
    -webkit-filter: invert(0.6);
            filter: invert(0.6);
}

.cursor {
    cursor: pointer;
}

.ce-user .custom-control,
.reference .custom-control,
.create-report .custom-control,
.filter-action .custom-control {
    padding-left: 0 !important;
}

.ce-user .custom-control-input,
.reference .custom-control-input,
.create-report .custom-control-input,
.filter-action .custom-control-input,
.edit_reports .pause_report .custom-control-input {
    left: auto !important;
    right: 0;
}

.ce-user .custom-control-label,
.reference .custom-control-label,
.create-report .custom-control-label,
.edit_reports .pause_report .custom-control-label {
    position: initial !important;
}

.ce-user .custom-control-label::before,
.ce-user.custom-control-label::after,
.reference .custom-control-label::before,
.reference.custom-control-label::after,
.create-report .custom-control-label::before,
.create-report.custom-control-label::after,
.edit_reports .pause_report .custom-control-label::before {
    left: auto !important;
    right: 0;
}

.ce-user .custom-control-label::after,
.reference .custom-control-label::after,
.create-report .custom-control-label::after,
.edit_reports .pause_report .custom-control-label::after {
    right: 0 !important;
    left: inherit;
}

.ce-user .custom-control-input:checked~.custom-control-label::before,
.reference .custom-control-input:checked~.custom-control-label::before,
.create-report .custom-control-input:checked~.custom-control-label::before,
.edit_reports .pause_report .custom-control-input:checked~.custom-control-label::before {
    background-color: #ffffff !important;
    border: 1px solid #686868 !important;
    border: 1px solid var(--dove-shade-gray) !important;
}

.ce-user .custom-checkbox .custom-control-input:checked~.custom-control-label::after,
.reference .custom-checkbox .custom-control-input:checked~.custom-control-label::after,
.create-report .custom-checkbox .custom-control-input:checked~.custom-control-label::after,
.filter-action .custom-checkbox .custom-control-input:checked~.custom-control-label::after,
.edit_reports .custom-checkbox.pause_report .custom-control-input:checked~.custom-control-label::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    background-image: none !important;
    font-size: 10px;
    padding: 1px;
    color: #686868;
    color: var(--dove-shade-gray);
    text-align: center;
}

.ce-user .custom-control-input:focus:not(:checked)~.custom-control-label::before,
.reference .custom-control-input:focus:not(:checked)~.custom-control-label::before,
.create-report .custom-control-input:focus:not(:checked)~.custom-control-label::before,
.filter-action .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border: 1px solid #686868 !important;
    border: 1px solid var(--dove-shade-gray) !important;
}

.home-nc .css-1s2u09g-control,
.reference .css-1s2u09g-control,
.removeAdminModal .css-1s2u09g-control,
.account-summary .css-1s2u09g-control,
.trending .css-1s2u09g-control {
    border-radius: 0;
    font-size: 12px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    color: #686868;
    color: var(--dove-shade-gray);
}

.home-nc .css-1pahdxg-control,
.reference .css-1pahdxg-control,
.trending .css-1pahdxg-control {
    box-shadow: none;
    border: 1px solid #ccc;
    color: #686868;
    color: var(--dove-shade-gray);
    border-radius: 0;
}

.home-nc .css-qc6sy-singleValue,
.reference .css-qc6sy-singleValue,
.trending .css-qc6sy-singleValue {
    font-size: 12px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    color: #686868;
    color: var(--dove-shade-gray);
}

.home-nc .css-9gakcf-option,
.reference .css-9gakcf-option,
.trending .css-9gakcf-option {
    background: #fdfcfc;
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 12px;
}

.home-nc .css-26l3qy-menu,
.reference .css-26l3qy-menu,
.trending .css-26l3qy-menu {
    font-size: 12px;
    color: #686868;
    color: var(--dove-shade-gray);
}

/* DataTable Common Classes Override */
.rdt_TableHeadRow {
    min-height: auto !important;
    border-bottom: none !important;
}

/*.home-nc .css-1okebmr-indicatorSeparator,*/
/*.reference .css-1okebmr-indicatorSeparator {*/
/*    background-color: transparent;*/
/*}*/
/*.home-nc .css-1gtu0rj-indicatorContainer:hover,*/
/*.reference .css-1gtu0rj-indicatorContainer:hover {*/
/*    background-color: var(--dove-shade-gray);*/
/*}*/
/*.home-nc .css-tlfecz-indicatorContainer,*/
/*.reference .css-tlfecz-indicatorContainer {*/
/*    color: var(--dove-shade-gray);*/
/*    background: #E4E4E4;*/
/*}*/

/*============= Login ================*/
.login-box {
    background: url(/static/media/bg-art.94edc8f1.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    height: 100vh;
}

.login-inner-box {
    background: #ffffff;
    background-repeat: no-repeat;
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    opacity: 0.89;
    padding: 20px 50px;
}

.card-body img {
    margin: 0 auto;
    display: block;
}

.card-inputs {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 12px 40px #00000014;
    opacity: 1;
    padding: 15px;
}

.card-inputs input {
    border: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0 !important;
    width: 200px;
    transition: width 1s;
    transition-timing-function: ease-out;
}

.card-inputs input:focus {
    color: #000000 !important;
    border: none;
    border-bottom: 1px solid #000000 !important;
    box-shadow: none;
    width: 100%;
}

.forgot-password,
.forgot-password a {
    color: #707070;
    color: var(--dim-shade-gray);
    font-size: 11px;
}

.forgot-password a:hover,
.access-token a:hover {
    color: #f77e0b;
    color: var(--orange-color);
    text-decoration: none;
}

.access-token,
.access-token a {
    color: #707070;
    color: var(--dim-shade-gray);
    font-size: 12px;
    margin-right: 10px;
    margin-top: 5px;
}

.login-form {
    border: none !important;
}

.card-title {
    font-weight: 300;
    font-weight: var(--fw-light);
}

.btn {
    font-size: 14px;
    margin-top: 20px;
}

.login-form {
    width: 330px;
    margin: 20px;
}

.sign-up {
    text-align: center;
    padding: 20px 0 0;
}

.alert {
    margin-bottom: -30px;
    font-size: 13px;
    margin-top: 20px;
}

/* .login-button {
    margin-right: 20px;
} */

.btn-login {
    background: #f77e0b;
    background: var(--orange-color);
    color: #333333;
    color: var(--dark-shade-gray);
    border: 1px solid #4a443b;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    padding: 3px 20px;
    border-radius: 6px;
}

.login-button .btn-login:hover {
    background: #ec411b;
    color: #ffffff;
    border: 1px solid #4a443b;
}

/*============= Layout ================*/

/*============= SettingBar ================*/
.setting-bar {
    background: #666666;
    background: var(--medium-dark-shade-gray);
    /*border: 1px solid #808080;*/
    min-height: 40px;
}

.site-title {
    color: #fff;
}

.setting-icons {
    background: #f6921e;
    /*border: 1px solid var(--dim-shade-gray);*/
    min-height: 40px;
}

.setting-icons ul li {
    padding-right: 15px;
}

.setting-icons ul li a {
    color: #333333;
    color: var(--dark-shade-gray);
    text-decoration: none;
}

/*============= Header ================*/
.header ul li a {
    color: #949494 !important;
}

.header ul li a.active-tab {
    color: #949494 !important;
    border-bottom: 2px solid #009345;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(246, 146, 30, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.custom-toggler.navbar-toggler {
    border-color: #f6921e !important;
}

/*============= Content Layout ================*/
.content-box {
    background: #cde3d0;
    min-height: calc(100vh - 161px);
}

.back-to-pre:hover {
    color: #686868;
    color: var(--dove-shade-gray);
}

/*============= To Do List ================*/
.todo-section {
    border: 1px solid #707070 !important;
    border: 1px solid var(--dim-shade-gray) !important;
    /* border-left: 1px solid var(--dim-shade-gray) !important;
    border-right: 1px solid var(--dim-shade-gray) !important;
    border-bottom: 1px solid var(--dim-shade-gray) !important; */
}

.todo-list h2 {
    color: #333333;
    color: var(--dark-shade-gray);
}

.todo-list .table-responsive {
    /* max-height: calc(100vh - 335px); */
    /* overflow-y: auto; */
}

/* .gmp_inspection_Prog_info .rdt_TableHead {
    position: sticky;
    top: 0px;
} */

.edit-link {
    cursor: pointer;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    color: #f6921e;
    text-decoration: underline;
}

.edit-link:hover {
    color: #686868;
    color: var(--dove-shade-gray);
}

.rdt_TableRow:nth-of-type(odd) {
    background: #ffffff;
}

.rdt_TableRow:nth-of-type(even) {
    background: #f6f1f1;
}

/*============= Tab Content ================*/
.tab-content .hCBnvI {
    min-height: 26px;
    border-bottom-color: transparent;
}

/* .tab-content ul li a {
    color: var(--dove-shade-gray);
    font-size: 15px;
    font-family: var(--font-family);
    font-weight: var(--fw-regular);
} */

/* .tab-box {
   border: 1px solid var(--dim-shade-gray) !important;
} */

.todo-list .tab-content .nav-link {
    cursor: pointer;
}

/* .tab-content .nav-link {
    background: #fff;
    margin-right: 7px;
    border: 1px solid var(--dim-shade-gray) !important;
    border-bottom: none !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
} */

/* .tab-content .nav-link.active {
    font-weight: var(--fw-semi-bold);
    margin-bottom: -2px !important;
    border-bottom: 1px solid white !important;
} */

.nav-tabs .nav-link.active {
    /* font-weight: var(--fw-semi-bold);
    border-bottom: 1px solid #fff !important; */
    /* border-top: 1px solid var(--dim-shade-gray) !important;
    border-left: 1px solid var(--dim-shade-gray) !important;
    border-right: 1px solid var(--dim-shade-gray) !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important; */
    border-top-color: #707070 !important;
    border-top-color: var(--dim-shade-gray) !important;
    border-right-color: #707070 !important;
    border-right-color: var(--dim-shade-gray) !important;
    border-left-color: #707070 !important;
    border-left-color: var(--dim-shade-gray) !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
}

.nav-tabs .nav-link {
    /* border: 1px solid var(--dim-shade-gray) !important; */
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    /* border: 1px solid var(--dim-shade-gray) !important; */
    border-top: 1px solid #707070 !important;
    border-top: 1px solid var(--dim-shade-gray) !important;
    border-left: 1px solid #707070 !important;
    border-left: 1px solid var(--dim-shade-gray) !important;
    border-right: 1px solid #707070 !important;
    border-right: 1px solid var(--dim-shade-gray) !important;
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
    font-weight: 400;
    font-weight: var(--fw-regular);
}

.tab-content ul li:first-child {
    margin-left: 12px;
}

.nav-tabs .nav-item {
    background-color: #fff !important;
    margin-right: 7px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}

.nav-tabs {
    border-bottom: none !important;
    /* border-bottom: 1px solid #000 !important; */
    /* border-bottom: 1px solid var(--dim-shade-gray) !important; */
}

.tab-content .btn-export {
    color: #333333;
    color: var(--dark-shade-gray);
    background: #f77e0b !important;
    background: var(--orange-color) !important;
    border: 1px solid #333333;
    border: 1px solid var(--dark-shade-gray);
    padding: 3px 10px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
}

.tab-content .btn-export:hover {
    background: #f8973b !important;
}

.tab-content .btn-history {
    color: #333333;
    color: var(--dark-shade-gray);
    background: #fabe78 !important;
    border: 1px solid #333333;
    border: 1px solid var(--dark-shade-gray);
    padding: 3px 10px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
}

.tab-content .btn-history:hover {
    background: #f8973b !important;
}

.dot {
    height: 17px;
    width: 17px;
    background-color: #707070;
    background-color: var(--dim-shade-gray);
    border-radius: 50%;
}

.dot-green {
    background: #009345;
}

.dot-orange {
    background: #f6921e;
}

.dot-red {
    background: #ff0000;
}

.dot-blue {
    background: #00b0f0;
}

.dot-due-complet-late {
    background-color: #ffc000;
}

.dot-complete-inProgress {
    background-color: #92d050;
}

.dot-overdue-missed {
    background-color: #ff0000;
}

/*============= Create NC ================*/
.home-nc {
    background: #fff;
    padding: 10px;
    /* border: 1px solid var(--dim-shade-gray) !important; */
}

.home-nc label {
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    color: #686868;
    color: var(--dove-shade-gray);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.home-nc .input-container input {
    border: 1px solid #ccc;
    box-sizing: border-box;
    outline: 0;
    padding: 0.75rem;
    position: relative;
    width: 100%;
}

.home-nc input[type="date"]::-webkit-calendar-picker-indicator,
.reference input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.home-nc .cal-position,
.reference .cal-position {
    position: absolute;
    right: 27px;
    top: 8px;
    font-size: 14px;
    color: #ccc;
    cursor: pointer;
}

.date-selection {
    border: 1px solid #ccc;
    color: #686868;
    color: var(--dove-shade-gray);
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
    position: relative;
}

.date-selection:after {
    content: "\f784";
    font-weight: bold;
    font-family: "Font Awesome 5 Pro";
    color: #ccc;
    position: absolute;
    top: 6px;
    right: 10px;
}

.home-nc .date-selection:focus-visible,
.reference .date-selection:focus-visible {
    outline: none;
}

.home-nc input[type="file"] {
    width: 75%;
}

.thumb-text {
    border: 1px solid #ccc;
    padding: 5px 10px;
}

/* Browse Modal */
.browse-modal .modal-footer,
.pro-browse-modal .modal-footer {
    border-top: none;
}

.browse-modal .modal-content,
.pro-browse-modal .modal-content,
.save-modal .modal-content,
.create-report,
.modal-content,
.error-modal .modal-content,
.audit-roles-modal .modal-content,
.feedback-modal .modal-content,
.feedback-success-modal .modal-content,
.frequency_modal .modal-content,
.history-logs-modal .modal-content,
.send-modal .modal-content {
    border-radius: 0 !important;
}

.evidence-data .custom-file-label:after {
    font-family: "Font Awesome 5 Pro";
    content: "\f07c Browse" !important;
}

.evidence-data .form-control {
    color: #686868 !important;
    color: var(--dove-shade-gray) !important;
    font-size: 14px !important;
    border: 1px solid #ccc;
    height: calc(1.5em + 0.75rem + 0px);
}

.custom-file-button {
    position: relative;
    top: -28px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    line-height: 1.5;
    color: #686868;
    color: var(--dove-shade-gray);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-file-button .btn-browse {
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    background: #e4e4e4;
    border: none;
    border-radius: 0;
    padding: 5px 12px;
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
    text-decoration: none;
    cursor: pointer;
}

/*.evidence-scroll {*/
/*    max-height: 140px;*/
/*    overflow-y: auto;*/
/*}*/

.evidence-scroll i {
    position: relative;
    top: -30px;
    right: -59px !important;
    color: #707070;
    color: var(--dim-shade-gray);
    background: #fff;
    border-radius: 50px;
    cursor: pointer;
}

.evidence-scroll img {
    width: 50px;
    height: 55px;
    max-height: 55px;
    margin-bottom: 10px;
}

.add-evidence {
    padding: 14px 0;
}

.add-more-evidence:hover {
    color: #666666;
    color: var(--medium-dark-shade-gray);
}

.add-more-evidence {
    position: relative;
    top: -10px;
}

.browse-modal .modal-footer .btn-cancel,
.pro-browse-modal .modal-footer .btn-cancel,
.history-logs-modal .modal-footer .btn-cancel {
    color: #666666;
    color: var(--medium-dark-shade-gray);
    background: transparent;
    border: 1px solid #666666;
    border: 1px solid var(--medium-dark-shade-gray);
    border-radius: 7px;
    font-size: 14px;
    font-weight: 300;
    font-weight: var(--fw-light);
    width: 142px;
    height: 31px;
}

.pro-browse-modal .modal-footer .btn-export {
    color: #333333;
    color: var(--dark-shade-gray);
    background: #f77e0b !important;
    background: var(--orange-color) !important;
    border: 1px solid #333333;
    border: 1px solid var(--dark-shade-gray);
    padding: 3px 10px;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    width: 142px;
    height: 31px;
}

.home-nc .custom-control label {
    font-weight: 300;
    font-weight: var(--fw-light);
}

.home-nc .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #686868 !important;
    border-color: var(--dove-shade-gray) !important;
    background-color: #f6921e !important;
}

.home-nc .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #686868;
    border-color: var(--dove-shade-gray);
}

.home-nc .custom-control-input:focus~.custom-control-label::before,
.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none !important;
}

.thumb-text {
    width: 73%;
}

/*.save-modal .img-box {*/
/*    background-image: url("../../Assets/images/thumbs-up.png");*/
/*    background-repeat: no-repeat;*/
/*    width: 282px;*/
/*    height: 248px;*/
/*    position: absolute;*/
/*    top: -40px;*/
/*    z-index: 1;*/
/*}*/

.create_nc {
    max-height: calc(100vh - 260px);
    overflow-y: auto;
}

/*============= Footer ================*/
.footer {
    background: #666666;
    background: var(--medium-dark-shade-gray);
    color: #fff;
    font-size: 11px;
}

.footer a {
    color: #ffffff;
    text-decoration: none;
}

.footer a:hover {
    color: #ffffff;
    text-decoration: none;
}

/*============= Programme Information ================*/
.overview {
    background: #fff;
    padding: 25px;
}

.overview h2 {
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.overview p {
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 12px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

/*============= Users ================*/
.ce-user {
    background: #fff;
    padding: 5px;
}

.green-check-mark {
    color: #009345;
}

.ce-select-options h2 {
    margin-bottom: 20px;
}

.ce-select-options label {
    font-size: 14px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    color: #686868;
    color: var(--dove-shade-gray);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

/*============= System Settings ================*/
.audit-roles-modal label {
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    color: #686868;
    color: var(--dove-shade-gray);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.audit-roles-modal .cancel {
    color: #686868;
    color: var(--dove-shade-gray);
    background: #ffffff;
    border: 1px solid #686868;
    border: 1px solid var(--dove-shade-gray);
    border-radius: 6px;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

/*============= Reference ================*/
.reference {
    background: #ffffff;
    padding: 10px;
}

.reference dl dt {
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.reference dl dd {
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 14px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
    margin-bottom: 0;
}

.reference .note h2 {
    font-size: 14px;
    color: #686868;
    color: var(--dove-shade-gray);
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.reference .note p,
.action-note {
    font-size: 12px;
    color: #686868;
    color: var(--dove-shade-gray);
    font-weight: 400;
    font-weight: var(--fw-regular);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.ref-evidence span {
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 12px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
    margin-left: 10px;
}

.reference .form-group label {
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.reference .form-group select {
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 12px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

/*============= Accounts ================*/
.accounts {
    background: #ffffff;
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    padding: 10px;
}

.accounts .btn-update-plan {
    background: #006e34;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    border-radius: 6px;
    text-align: center;
    border: 1px solid #009345;
    width: 181px;
    height: 28px;
}

.accounts .btn-cancel-plan,
.azure-settings .btn-cancel-plan {
    background: #ffffff;
    color: #666666;
    color: var(--medium-dark-shade-gray);
    font-size: 14px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    border-radius: 6px;
    text-align: center;
    border: 1px solid #666666;
    border: 1px solid var(--medium-dark-shade-gray);
    width: 181px;
    height: 28px;
}

.billing-info p {
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
    width: 160px;
}

.billing-history .price {
    width: 60px;
    float: left;
    margin-right: 40px;
}

.new-card-info input {
    color: #b9b5b5;
    border: 1px solid #cccccc;
    padding: 3px 10px;
    font-size: 14px;
}

.new-card-info input:focus-visible {
    outline: none;
}

.new-card-info .new-card-num {
    width: 50%;
}

.new-card-info .new-card-date,
.new-card-info .new-card-cvc {
    width: 22%;
    text-align: center;
}

.history-logs-modal .table tbody {
    display: block;
    max-height: 200px;
    overflow-y: auto;
}

.history-logs-modal .table thead,
tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.history-logs-modal tbody tr:nth-of-type(even) {
    background-color: rgba(246, 241, 241, 0.2);
}

/*============= Current Plan ================*/
.current-plan {
    background: #ffffff;
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    padding: 20px;
}

.current-plan h2 {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}

.current-plan h3 {
    color: #424242;
    font-size: 16px;
    font-weight: 600;
}

.current-plan p {
    color: #666666;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}

.plans {
    margin-top: 15px;
}

.plans h2 {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    font-weight: var(--fw-regular);
}

.plans dl dt {
    margin-bottom: 10px;
}

.plans dl dt h2 {
    color: #424242;
    font-size: 16px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    margin-bottom: 0;
}

.plans dl dt p {
    color: #666666;
    font-size: 16px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
}

.switch-plan-btn {
    background: #ffffff;
    border: 1px solid #666666;
    border-radius: 6px;
    width: 177.02px;
    height: 28px;
    font-size: 14px;
    font-weight: 600;
    color: #666666;
}

.current-plan-btn {
    background: #ffffff;
    width: 177.02px;
    height: 28px;
    font-size: 14px;
    font-weight: 600;
    color: #666666;
    border: none;
}

.plan-divider {
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    padding: 15px 0;
}

/*============= Accounts Billing ================*/
.accounts-billing {
    background: #ffffff;
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    padding: 10px;
}

.profile_summary {
    background: none !important;
    border: none !important;
    padding: 0px !important;
}

.accounts-billing label {
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
}

.accounts-billing .btn-update-plan,
.plans .btn-update-plan,
.azure-settings .btn-update-plan {
    background: #006e34;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    border-radius: 6px;
    text-align: center;
    border: 1px solid #009345;
    width: 181px;
    height: 28px;
}

.accounts-billing .btn-cancel-plan,
.plans .btn-cancel-plan {
    background: #ffffff;
    color: #666666;
    color: var(--medium-dark-shade-gray);
    font-size: 14px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    border-radius: 6px;
    text-align: center;
    border: 1px solid #666666;
    border: 1px solid var(--medium-dark-shade-gray);
    width: 181px;
    height: 28px;
}

.cplan {
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 35px;
}

/*============= Checkout Stripe ================*/
.checkout,
.thanks {
    background: #ffffff;
    /*border: 1px solid var(--dim-shade-gray);*/
    /*padding: 50px 50px;*/
    margin: 50px 50px;
}

.checkout label {
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    letter-spacing: 0px;
    color: #686868;
}

.checkout-form h3,
.thanks h3 {
    font-size: 14px;
    color: #949494;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
}

.checkout-form h2,
.thanks h2 {
    font-size: 30px;
    color: #303030;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
}

.checkout-form dl dt,
.checkout-form dl dd,
.thanks dl dt,
.thanks dl dd {
    color: #424242;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
}

.checkout-form dl dt:nth-child(even),
.checkout-form dl dd:nth-child(even),
.thanks dl dt:nth-child(even),
.thanks dl dd:nth-child(even) {
    color: #949494 !important;
}

.checkout-form .stripe-card,
.checkout-form .stripe-account {
    background: #ffffff;
    width: 100%;
    height: 45px;
    color: #bebbbb;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
}

.checkout-form .stripe-active {
    color: #686868;
    border: 1px solid #686868;
    border-radius: 5px;
}

.checkout-form .form-check-label {
    color: #686868;
    font-size: 16px;
    font-weight: 400;
    font-weight: var(--fw-regular);
}

.checkout-form p {
    font-size: 12px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    letter-spacing: 0px;
    color: #686868;
}

.btn-start-trial {
    background: #f77e0b;
    border: 1px solid #707070;
    border-radius: 5px;
    width: 381px;
    height: 45px;
    font-size: 18px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    color: #ffffff;
}

.checkout-form a {
    text-decoration: underline;
    font-size: 12px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    letter-spacing: 0px;
    color: #686868;
}

.checkout-form a:hover {
    color: #686868;
}

.mail-box {
    background: #ffffff;
    box-shadow: 0px 12px 40px #00000014;
    padding: 100px 30px 40px;
}

.mail-box h2 {
    color: #666666;
    font-size: 18px;
    font-weight: 600;
}

.mail-box p {
    color: #666666;
    font-size: 12px;
    font-weight: 400;
    font-weight: var(--fw-regular);
}

.mail-box p a {
    color: #666666;
    font-size: 12px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    text-decoration: underline;
}

.mail-icon {
    position: relative;
}

.mail-icon img {
    position: absolute;
    top: -190px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.spinner-position {
    position: relative;
    top: -3px;
    left: 5px;
}

/*============= Subscriptions ================*/
.subscription {
    background: #ffffff;
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    padding: 15px 0;
    margin-top: 40px;
}

.subscription .rdt_TableHead {
    margin-bottom: -5px;
}

/*============= Feedback ================*/
.feedback-modal h2 {
    color: #333333;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.feedback-modal h3 {
    color: #666666;
    font-size: 18px;
    font-weight: 600;
}

.feedback-modal p {
    color: #666666;
    font-size: 12px;
    font-weight: 400;
}

.feedback-modal form label {
    color: #686868;
    font-size: 14px;
    font-weight: 600;
}

.feedback-modal .feedback-btn {
    background: #f77e0b;
    border: 1px solid #f77e0b;
    border-radius: 5px;
    width: 285px;
    height: 32px;
    color: #ffffff;
}

.feedback-modal .btn-cancel {
    background: #ffffff;
    border: 0.89px solid #666666;
    border-radius: 6px;
    width: 141.51px;
    height: 32px;
}

/*============= Glossary ================*/
.glossary {
    background: #ffffff;
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    padding: 10px;
}

.glossary .accordion .card-header {
    background-color: #fbf9f9;
}

.glossary h2 {
    margin-left: 5px;
}

.glossary h3 {
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    padding: 15px 10px;
    text-decoration: underline;
}

.glossary .accordion .card-header button {
    color: #686868;
    font-size: 16px;
    font-weight: 600;
}

.glossary .accordion .card-header button::after {
    content: "\f077";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    float: right;
}

.glossary .accordion .card-header button.collapsed::after {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    float: right;
}

.glossary .page-link {
    color: #686868;
    font-size: 18px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
}

.glossary .page-link:hover {
    z-index: 2;
    color: #fff;
    text-decoration: none;
    background-color: #cde3d0;
    border-color: #686868;
}

.scroll-top .scroll-btn {
    position: fixed;
    width: 10%;
    right: 27px;
    bottom: 40px;
    height: 61px;
    font-size: 16px;
    z-index: 1;
    cursor: pointer;
    background: #686868;
    opacity: 0.9;
    color: #fff;
    border: none;
    border-radius: 6px;
}

.btn-export {
    color: #333333;
    color: var(--dark-shade-gray);
    background: #f77e0b !important;
    background: var(--orange-color) !important;
    border: 1px solid #333333;
    border: 1px solid var(--dark-shade-gray);
    padding: 3px 10px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
}

.btn-export:hover {
    background: #f8973b !important;
}

/*============= GMP Inspections ================*/
.gmp_inspections_wrapper .add_gmp_ins_btn {
    background: #ffffff;
    color: #666666;
    color: var(--medium-dark-shade-gray);
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    border-radius: 6px;
    text-align: center;
    border: 1px solid #666666;
    border: 1px solid var(--medium-dark-shade-gray);
    width: 181px;
    height: 28px;
}

.gmp_inspection_modal .custom_mb_heading {
    margin-bottom: 36px;
}

.gmp_inspection_modal .css-1s2u09g-control {
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    color: #686868;
    color: var(--dove-shade-gray);
}

.gmp_inspection_modal .css-1pahdxg-control {
    box-shadow: none;
    border: 1px solid #ccc;
    color: #686868;
    color: var(--dove-shade-gray);
    border-radius: 0;
}

.gmp_inspection_modal input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.gmp_inspection_modal input[type="checkbox"]:after {
    line-height: 1em;
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-top: -14px;
    margin-left: 0px;
    border: 1px solid #adb5bd;
    border-radius: 2px;
    background: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.gmp_inspection_modal input[type="checkbox"]:checked:after {
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
    background-image: none !important;
    padding: 1px;
    color: #686868;
    color: var(--dove-shade-gray);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.gmp_inspection_modal .cancel {
    width: 91px;
}

.gmp_inspection_modal .btn-send {
    padding: 3px 10px;
    height: inherit;
}

/*============= Frequency Modal ================*/
.frequency_modal .cancel {
    color: #686868;
    color: var(--dove-shade-gray);
    background: #ffffff;
    border: 1px solid #686868;
    border: 1px solid var(--dove-shade-gray);
    border-radius: 6px;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
    width: 91px;
}

.frequency_modal .custom_modal_content_padding {
    padding-top: 63px;
    padding-bottom: 63px;
}

.gmp_guide .download {
    color: #686868;
    color: var(--dove-shade-gray);
    background: #ffffff;
    border: 1px solid #686868;
    border: 1px solid var(--dove-shade-gray);
    border-radius: 6px;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

/*============= GMP Guide ================*/
.gmp_guide {
    background: #ffffff;
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    padding: 50px 20px 20px 20px;
    margin-top: 40px;
}

.gmp_guide .hCBnvI {
    min-height: 26px;
    border-bottom-color: transparent;
}

/*============= GMP Questions ================*/
.gmp_questions {
    background: #ffffff;
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    padding: 15px 0;
    margin-top: 20px;
}

.gmp_questions .hCBnvI {
    min-height: 26px;
    border-bottom-color: transparent;
}

.gmp_questions .bVUXHv {
    text-align: left !important;
}

.gmp_questions_export_btn .btn-export {
    color: #333333;
    color: var(--dark-shade-gray);
    background: #f77e0b !important;
    background: var(--orange-color) !important;
    border: 1px solid #333333;
    border: 1px solid var(--dark-shade-gray);
    padding: 3px 10px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
}

.gmp_questions_export_btn .btn-export:hover {
    background: #f8973b !important;
}

.gmp_ref_question .btn_clear_inspection {
    background: #009345 !important;
    border: 1px solid #ffffff !important;
    border-radius: 6px !important;
    color: #ffffff !important;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.gmp_ref_question .btn_clear_inspection:hover {
    background: #33a96a !important;
}

.gmp_ref_question .redirect_detail {
    cursor: pointer;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    color: #707070;
    color: var(--dim-shade-gray);
}

.gmp_ref_question .redirect_detail:hover {
    opacity: 0.9;
}

.gmp_ref_question .clear_item {
    background: #f77e0b;
    background: var(--orange-color);
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    border: 1px solid #ffffff;
    border-radius: 6px;
    color: #ffffff;
}

.gmp_ref_question .clear_item:hover {
    opacity: 0.8;
}

.gmp_questions_details .btn-group .btn-success,
.gmp_questions_details .btn-group .btn-warning,
.gmp_questions_details .btn-group .btn-danger {
    padding: 15px;
    border: 4px solid #fff;
}

.gmp_questions_details .btn-group .btn-success {
    border-radius: 15px 0px 0px 15px;
    background: #248332;
}

.gmp_questions_details .btn-group .btn-success.btn_success {
    opacity: 0.25;
}

.gmp_questions_details .btn-group .btn-danger {
    border-radius: 0px 15px 15px 0px;
    background: #ec411b;
}

.gmp_questions_details .btn-group .btn-danger.btn_danger {
    opacity: 0.25;
}

.gmp_questions_details .btn-group .btn-warning {
    background: #f77e0b;
}

.gmp_questions_details .btn-group .btn-warning.btn_warning {
    opacity: 0.25;
}

.gmp_questions_details .btn-group .btn-success:hover,
.gmp_questions_details .btn-group .btn-warning:hover,
.gmp_questions_details .btn-group .btn-danger:hover {
    opacity: 0.9;
}

.gmp_questions_details .btn-group {
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    border-radius: 15px;
}

.gmp_questions_details .btn.btn-block {
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    border-radius: 15px;
    padding: 10px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-size: 18px;
    color: #707070;
    color: var(--dim-shade-gray);
}

.gmp_questions_details .form-group .custom_text_area {
    border-color: #707070;
    border-color: var(--dim-shade-gray);
}

.gmp_questions_details .prev_next button {
    background: none;
    border: none;
}

.gmp_questions_details input[type="checkbox"]:after {
    line-height: 1em;
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-top: -7px;
    margin-left: 0px;
    border: 4px solid #adb5bd;
    border-radius: 2px;
    background: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border-style: double;
}

.gmp_questions_details input[type="checkbox"]:checked:after {
    cursor: pointer;
    background-color: rgb(255, 255, 255) !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAhCAYAAABEM4KbAAAABHNCSVQICAgIfAhkiAAAAp1JREFUWEfNmEF2EkEQhqt6spcbiA57Gy4gnCB4ApMTGNcKD0HXiSdIPEG4QfAC0NmDD2+Ae6Yr1SQoCdPTNQNMnE0W9FR/XV311z9B+A+eak9XVQTHCKqJQBUi0IjIf2meWGrhczLGX/V7JDwDQJ3GQQB/ksTqZ4GMB7oNhOecrao3SUS3S0vtec/MS4eM+41LRDjJukHO4PdZZ8wZvn9Kg+S6qxxFeOO72jUQkT2ddc3V5iFKgdwFsJRM7gpYCmSRGnxarwe97tf9xplCOM+UOaKf0+6kmbXmYJC1geaNFTeK/yGg30lCmmVmUTqkq8NI4SRTB5lqqWx9/smY0EA5SCbjQeOaA7ezNrcEH391xxchwIM0zr7q8GA6Wf2mdZTgjTMHvgw9zONqqA5TId2wB1I8U6nJY8sQ0tXss/khuY71mtqgPglNFADbmnbMKE9cfBDbaw6eIgM0WiZ06oZ8KCjX4QXX4YfAui/TzrgXirWlk3x6N0+9OsWebpFE1MrqQudq2AvyQbP0JqyHvreRM7DgDLzIju8HldWhTA/9kH19gqguQ1ewyqilV5sFL9HDVaMo25TooRfS/RALQQHITDuTep5GIbDvZh0zDCUh6/e/Yi4FXRtSkXFI8YZFYB9NHGGHAoMOQxPlqbsuArd+Z2ssxv36kMX4eJegrLW37GxSP66KxN2CXDVDhAYBXxYJuI9G2dLJNBCJzfIdII9xkCbB64Kk9flooz1fs7cmNzet9esGEN9ITyz1h9J4Ikg3TY6sYtMgegrNZUnkoOmV+EPpZ4AEKG1NENK9xNc+4mt/698kv/3KAyyCdP/1iiLFsrRtRPYp2pmzW3KqlduxyJPmn36WAejYRJncPMR9M0FlmYDJ8wkgSYRvzR2f90gRvMJQKAAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: center center;
}

.gmp_status_filter .custom_select {
    border-radius: 0;
    font-weight: 400 !important;
    font-weight: var(--fw-regular) !important;
    color: #686868 !important;
    color: var(--dove-shade-gray) !important;
    width: 150px;
    padding: 5px;
}

.gmp_status_filter.risk_field .custom_select {
    border-radius: 0;
    font-weight: 400 !important;
    font-weight: var(--fw-regular) !important;
    color: #686868 !important;
    color: var(--dove-shade-gray) !important;
    width: 250px;
    padding: 5px;
}

.gmp_status_filter.nc-action .custom_select {
    border-radius: 0;
    font-weight: 400 !important;
    font-weight: var(--fw-regular) !important;
    color: #686868 !important;
    color: var(--dove-shade-gray) !important;
    width: 230px;
    padding: 5px;
}

.gmp_status_filter select:focus-visible {
    outline: none;
}

.custom_create_gmp .clear_item {
    background: #f77e0b;
    background: var(--orange-color);
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    border: 1px solid #ffffff;
    border-radius: 6px;
    color: #ffffff;
}

.custom_create_gmp .clear_item:hover {
    opacity: 0.8;
}

.custom_create_gmp .btn_clear_inspection {
    background: #009345 !important;
    border: 1px solid #ffffff !important;
    border-radius: 6px !important;
    color: #ffffff !important;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.custom_create_gmp .btn_clear_inspection:hover {
    background: #33a96a !important;
}

.gmp_inspections_wrapper .rdt_TableBody .rdt_TableRow,
.prog_info .rdt_TableBody .rdt_TableRow {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

/*============= Checkout Page ================*/
.err_feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

/*============= Edit Reminder ================*/
/* .edit_reminder input[type="checkbox"]:after {
    line-height: 1em;
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-top: -14px;
    margin-left: 0px;
    border: 1px solid #adb5bd;
    border-radius: 2px;
    background: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.edit_reminder input[type="checkbox"]:checked:after {
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
    background-image: none !important;
    padding: 1px;
    color: var(--dove-shade-gray);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
} */

.edit_reminder.ce-user .custom-control-input {
    left: 0 !important;
}

.edit_reminder.ce-user .custom-control-label::before {
    left: 0 !important;
}

.edit_reminder.ce-user .custom-control-label::after {
    left: 0 !important;
}

.edit_reminder.ce-user .custom-control-label {
    margin-left: 30px;
}

/*============= Send Gmp to Inspectors ==================*/
.send_gmp_to_inspectors .clear_item {
    background: #f77e0b;
    background: var(--orange-color);
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    border: 1px solid #ffffff;
    border-radius: 6px;
    color: #ffffff;
}

.send_gmp_to_inspectors .clear_item:hover {
    opacity: 0.8;
}

.send_gmp_to_inspectors .btn_clear_inspection {
    background: #009345 !important;
    border: 1px solid #ffffff !important;
    border-radius: 6px !important;
    color: #ffffff !important;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.send_gmp_to_inspectors .btn_clear_inspection:hover {
    background: #33a96a !important;
}

.custom_text_overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* ================ SA Question Detail =============== */
.sa_question_detail .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #686868 !important;
    border-color: var(--dove-shade-gray) !important;
    background-color: #f6921e !important;
}

/* ================ Modal Backdrop =============== */

.modal_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1051;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
}

.custom_modal_backdrop .modal-content {
    width: 100%;
    min-height: 200px;
}

.custom_modal_backdrop .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1052;
    display: unset;
    width: auto;
    height: unset;
    overflow: unset;
    outline: unset;
}

.custom_modal_backdrop .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: unset;
    justify-content: unset;
    height: unset;
}

/* ================ Delete NC Record Modal =============== */
.delete_nc .delete_item {
    background: #f77e0b;
    background: var(--orange-color);
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    border: 1px solid #ffffff;
    border-radius: 6px;
    color: #ffffff;
}

.delete_nc .delete_item:hover {
    opacity: 0.8;
}

.delete_nc .delete_nc_success {
    background: #009345 !important;
    border: 1px solid #ffffff !important;
    border-radius: 6px !important;
    color: #ffffff !important;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.delete_nc .delete_nc_success:hover {
    background: #33a96a !important;
}

/*==================== Additional Standards ====================*/
.additional_standard .cancel,
.draft_schedule .cancel {
    background: #f77e0b;
    background: var(--orange-color);
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    border: 1px solid #ffffff;
    border-radius: 6px;
    color: #ffffff;
}

.additional_standard .cancel:hover,
.draft_schedule .cancel:hover {
    opacity: 0.8;
}

.additional_standard .add_remove,
.draft_schedule .add_remove {
    background: #009345 !important;
    border: 1px solid #ffffff !important;
    border-radius: 6px !important;
    color: #ffffff !important;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.additional_standard .add_remove:hover,
.draft_schedule .add_remove:hover {
    background: #33a96a !important;
}

.standards_logs .rdt_TableCell {
    display: block !important;
}

/*================== Toogle Switch Log Standards =================*/
.log_status_switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}

.log_status_switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider_status {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: red;
    transition: 0.4s;
}

.slider_status:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 6px;
    bottom: 4px;
    background-color: #fff;
    transition: 0.4s;
    z-index: 0;
}

input:checked+.slider_status {
    background-color: green;
}

input:checked+.slider_status:before {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    font-family: "fon";
}

/* Rounded sliders */
.slider_status.round {
    border-radius: 34px;
}

.slider_status.round:before {
    border-radius: 50%;
}

input[type="checkbox"].custom-disabled:checked+.slider_status {
    background-color: #ccc !important;
}

.log_status i.fa-check {
    position: absolute;
    top: 6px;
    left: 8px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
}

.log_status i.fa-times {
    position: absolute;
    top: 7px;
    right: 8px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
}

/*====================== Sign In Button Microsoft ======================*/
.login-button .btn-signin-ms {
    background: #5f5951;
    color: #fff;
    border: 1px solid #4a443b;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    padding: 3px 20px;
    border-radius: 6px;
}

.login-button .btn-signin-ms:hover {
    background: #47433d;
}

/*====================== Create Multi-site ======================*/
.btn-cancel {
    color: #fff;
    background: #f77e0b !important;
    background: var(--orange-color) !important;
    border: 1px solid #ffffff !important;
    border-radius: 6px !important;
    color: #ffffff !important;
    width: 91px;
    height: 28px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.btn-cancel:hover {
    opacity: 0.8;
}

.btn-yes {
    background: #009345;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    border: 1px solid #ffffff;
    border-radius: 6px;
    color: #ffffff;
}

.btn-no {
    background: #f77e0b;
    background: var(--orange-color);
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    border: 1px solid #ffffff;
    border-radius: 6px;
    color: #ffffff;
}

.btn-yes:hover,
.btn-no:hover {
    opacity: 0.8;
}

.multi-site {
    height: calc(100vh - 335px);
    overflow-y: auto;
}

.btn-resend-password {
    border: 1px solid grey;
    color: #686868;
    color: var(--dove-shade-gray);
    background-color: #fff;
    border-radius: 7px !important;
    font-size: 12px !important;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    font-family: "Open Sans", sans-serif;
    font-family: var(--font-family);
}

.btn-resend-password:hover {
    opacity: 0.8;
}

/*====================== new payment method ======================*/
.removeAdminModal .custom-control,
.account-summary .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 0px;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

.removeAdminModal .custom-control-label,
.account-summary .custom-control-label {
    position: initial;
    margin-bottom: 0;
    vertical-align: top;
    /* right: 18px; */
}

.removeAdminModal .custom-control-input,
.account-summary .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.removeAdminModal .custom-radio .custom-control-label::before,
.account-summary .custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.removeAdminModal .custom-control-label::before,
.account-summary .custom-control-label::before {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.removeAdminModal .custom-control-label::before,
.account-summary .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: unset;
    right: 0 !important;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.removeAdminModal .custom-control-label::after,
.account-summary .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: unset;
    right: 0 !important;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50%/50% 50% no-repeat;
}

.removeAdminModal .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #686868 !important;
    border-color: var(--dove-shade-gray) !important;
    background-color: rgba(104, 104, 104, 0.45) !important;
}

/* Multisite admin users overiew */
.red-cross-mark {
    color: red;
}

.sites-multisite {
    background: #fff;
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    padding: 15px 0;
}

.account-summary .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #686868 !important;
    border-color: var(--dove-shade-gray) !important;
    background-color: #f6921e !important;
}

.edit-multisite-user .custom-checkbox .custom-input:before {
    position: absolute;
    top: 0.25rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
    border-radius: 0.25rem;
}

.edit-multisite-user .custom-checkbox .custom-input:checked::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    background-image: none !important;
    font-size: 10px;
    padding: 1px;
    color: #686868;
    color: var(--dove-shade-gray);
    border: 1px solid #686868 !important;
    border: 1px solid var(--dove-shade-gray) !important;
    border-radius: 0.25rem;
    text-align: center;
    position: absolute;
    top: 0.25rem;
    width: 1rem;
    height: 1rem;
}

/*============= Azure Settings ================*/
.azure-settings {
    background-color: #fff;
    height: calc(100vh - 335px);
    overflow-y: auto;
    padding: 10px;
}

.setup-guide {
    text-decoration: underline;
}

.setup-guide:hover {
    color: #424242;
}

/*============= GMP & SA Questions Logs ================*/
.btn-logs {
    color: #333333 !important;
    color: var(--dark-shade-gray) !important;
    background: #fabe78 !important;
    border: 1px solid #333333 !important;
    border: 1px solid var(--dark-shade-gray) !important;
    padding: 3px 10px !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    font-weight: var(--fw-semi-bold) !important;
}

.btn-logs:hover {
    background: #f8973b !important;
}

/*============= PRO Action ================*/
.pro-ref input[type="checkbox"]:after {
    line-height: 1em;
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-top: -7px;
    margin-left: 0px;
    border: 4px solid #adb5bd;
    border-radius: 2px;
    background: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border-style: double;
}

.pro-ref input[type="checkbox"]:checked:after {
    cursor: pointer;
    background-color: rgb(255, 255, 255) !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAhCAYAAABEM4KbAAAABHNCSVQICAgIfAhkiAAAAp1JREFUWEfNmEF2EkEQhqt6spcbiA57Gy4gnCB4ApMTGNcKD0HXiSdIPEG4QfAC0NmDD2+Ae6Yr1SQoCdPTNQNMnE0W9FR/XV311z9B+A+eak9XVQTHCKqJQBUi0IjIf2meWGrhczLGX/V7JDwDQJ3GQQB/ksTqZ4GMB7oNhOecrao3SUS3S0vtec/MS4eM+41LRDjJukHO4PdZZ8wZvn9Kg+S6qxxFeOO72jUQkT2ddc3V5iFKgdwFsJRM7gpYCmSRGnxarwe97tf9xplCOM+UOaKf0+6kmbXmYJC1geaNFTeK/yGg30lCmmVmUTqkq8NI4SRTB5lqqWx9/smY0EA5SCbjQeOaA7ezNrcEH391xxchwIM0zr7q8GA6Wf2mdZTgjTMHvgw9zONqqA5TId2wB1I8U6nJY8sQ0tXss/khuY71mtqgPglNFADbmnbMKE9cfBDbaw6eIgM0WiZ06oZ8KCjX4QXX4YfAui/TzrgXirWlk3x6N0+9OsWebpFE1MrqQudq2AvyQbP0JqyHvreRM7DgDLzIju8HldWhTA/9kH19gqguQ1ewyqilV5sFL9HDVaMo25TooRfS/RALQQHITDuTep5GIbDvZh0zDCUh6/e/Yi4FXRtSkXFI8YZFYB9NHGGHAoMOQxPlqbsuArd+Z2ssxv36kMX4eJegrLW37GxSP66KxN2CXDVDhAYBXxYJuI9G2dLJNBCJzfIdII9xkCbB64Kk9flooz1fs7cmNzet9esGEN9ITyz1h9J4Ikg3TY6sYtMgegrNZUnkoOmV+EPpZ4AEKG1NENK9xNc+4mt/698kv/3KAyyCdP/1iiLFsrRtRPYp2pmzW3KqlduxyJPmn36WAejYRJncPMR9M0FlmYDJ8wkgSYRvzR2f90gRvMJQKAAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: center center;
}

/*============= Programme Information Overview Download Buttons =============*/
.overview-btn-download {
    padding: 3px 10px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    border: 1px solid #707070;
    border: 1px solid var(--dim-shade-gray);
    background-color: transparent;
    color: #333333;
    color: var(--dark-shade-gray);
}

.overview-btn-download:hover {
    color: #333333;
    color: var(--dark-shade-gray);
    text-decoration: none;
}

/*============= KPI ================*/
.kpi {
    background-color: #fff;
    height: calc(100vh - 335px);
    overflow-y: auto;
    padding: 15px;
}

/* .kpi .dropdown {
    width: 190px;
} */

.kpi .report-dropdown {
    border: 1px solid rgb(0 0 0 / 30%);
    color: #686868;
    color: var(--dove-shade-gray);
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    padding: 8px 0px 8px 8px;
    background: none;
    width: 100%;
    text-align: left;
    font-size: 14px;
}

.kpi .report-dropdown::after {
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    background-color: rgb(112 112 112 / 35%);
    padding: 6px 13px 5px 13px;
    margin-left: 10px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 18px;
    font-weight: 700;
}

.filter-action .custom-control-label::before,
.filter-action .custom-control-label::after {
    right: 0 !important;
    left: 0;
}

.filter-action .custom-control-label {
    padding-left: 30px;
}

.filter-action .custom-control-input:checked~.custom-control-label::before {
    background-color: #ffffff !important;
    border: 1px solid #686868 !important;
    border: 1px solid var(--dove-shade-gray) !important;
    left: 0;
}

.kpi .dropdown ul.dropdown-menu li:first-child {
    margin-left: 0;
}

.charts .btn-group {
    border: 1px solid rgb(128 128 128);
    border-radius: 10px;
}

.charts .btn-group .btn-bar {
    background-color: rgb(128 128 128 / 30%);
    border: none;
    border-radius: 10px 0px 0px 10px;
}

.charts .btn-group .btn-pie {
    background-color: rgb(128 128 128 / 30%);
    border: none;
    border-radius: 0px 10px 10px 0px;
}

.charts .btn-transparent {
    background-color: transparent;
}

.charts .arrow_box,
.trending .charts .arrow_box {
    position: relative;
    background: #555;
    border: 1px solid #555;
    border-radius: 5px;
    padding: 5px;
}

.charts .arrow_box:after,
.charts .arrow_box:before,
.trending .charts .arrow_box:after,
.trending .charts .arrow_box:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.charts .arrow_box:after,
.trending .charts .arrow_box:after {
    border-color: rgba(85, 85, 85, 0);
    border-right-color: #555;
    border-width: 5px;
    margin-top: -5px;
}

.charts .arrow_box:before,
.trending .charts .arrow_box:before {
    border-color: rgba(0, 0, 0, 0);
    border-right-color: #555;
    border-width: 8px;
    margin-top: -8px;
}

.charts #chart .apexcharts-tooltip {
    color: #fff;
    -webkit-transform: translateX(10px) translateY(5px);
            transform: translateX(10px) translateY(5px);
    overflow: visible !important;
    white-space: normal !important;
}

.charts #chart .apexcharts-tooltip span {
    padding: 5px 10px;
    display: inline-block;
}

/* .kpi .charts .apexcharts-toolbar {
    display: none;
} */

.kpi .custom-grid-container {
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
}

.download-reports {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

/*============= Trending ================*/
.trending {
    background-color: #fff;
    height: calc(100vh - 335px);
    overflow-y: auto;
    padding: 20px;
}

.completion-gmp .report-dropdown,
.completion-sa .report-dropdown {
    border: 1px solid rgb(0 0 0 / 30%);
    color: #686868;
    color: var(--dove-shade-gray);
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    padding: 4px 0px 4px 12px;
    background: none;
    width: 100%;
    text-align: left;
    font-size: 14px;
    height: 38px;
}

.completion-gmp .report-dropdown::after,
.completion-sa .report-dropdown::after {
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    background-color: rgb(112 112 112 / 35%);
    padding: 6px 13px 4px;
    margin-left: 10px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 18px;
    /* font-weight: 600; */
}

.completion-gmp-filter ul li:first-child {
    margin-left: 0;
}

.trending .gmp-inspections-score .react-select-trending__control {
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    color: #686868;
    color: var(--dove-shade-gray);
    border: 1px solid #b2b2b2;
    cursor: pointer;
}

.trending .gmp-inspections-score .css-tlfecz-indicatorContainer {
    color: #686868;
    color: var(--dove-shade-gray);
}

.trending .react-select-trending__single-value {
    font-weight: 400;
    font-weight: var(--fw-regular);
    color: #686868;
    color: var(--dove-shade-gray);
}

.trending .react-select-trending__option {
    background: #fdfcfc;
    color: #686868;
    color: var(--dove-shade-gray);
}

.trending .react-select-trending__option {
    cursor: pointer;
}

.trending .react-select-trending__indicator-separator {
    display: none !important;
}

.trending .react-select-trending__indicators {
    background-color: #cdcdcd !important;
}

/* .trending .charts .apexcharts-toolbar {
    display: none;
} */

/* .completion-sa-table th,
td {
    white-space: nowrap;
    height: 60px;
    min-height: 45px;
    max-height: 80px;
} */
/* 
.completion-sa-table td {
    width: 120px;
    position: sticky;
    top: 0;

} */

/* .completion-sa-table .first-col {
    position: absolute;
    width: 15%;
    margin-left: -15%;
    text-wrap: wrap;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
} */

/* .completion-sa-table .table-wrapper {
    overflow-x: scroll;
    width: 85%;
    margin-left: 15%;
}

.completion-sa-table .bg-striped {
    background-color: #f0f0f0;
} */

/* .completion-sa-table table {
    font-family: "Fraunces", serif;
    font-size: 125%;
    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    border: 1px solid black;
  }
  .completion-sa-table table td,
  .completion-sa-table table th {
    border: 1px solid black;
    padding: 0.5rem 1rem;
  }
  .completion-sa-table table thead th {
    padding: 3px;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 25vw;
    background: white;
  }
  .completion-sa-table table td {
    background: #fff;
    padding: 4px 5px;
    text-align: center;
  }
  
  .completion-sa-table table tbody th {
    font-weight: 100;
    font-style: italic;
    text-align: left;
    position: relative;
  }
  .completion-sa-table table thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }
  .completion-sa-table table tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
  }
  .completion-sa-table caption {
    text-align: left;
    padding: 0.25rem;
    position: sticky;
    left: 0;
  }
  
  .completion-sa-table [role="region"][aria-labelledby][tabindex] {
    width: 100%;
    max-height: 98vh;
    overflow: auto;
  }
  .completion-sa-table [role="region"][aria-labelledby][tabindex]:focus {
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    outline: 0;
  } */

.completion-sa-table .container {
    width: 100%;
    max-width: 100%;
    height: 470px;
    /* background-color: #ddd; */
    overflow: auto;
    /* border: 1px solid #ccc; */
    border: none;
    padding-left: 0;
    padding-right: 0;
}

.completion-sa-table table {
    table-layout: fixed;
    width: 100%;
    overflow-x: scroll;
    border-collapse: collapse;
}

/* .completion-sa-table td,
th {
    border: 1px solid #ccc;
} */

/* .completion-sa-table th {
    font-weight: 600;
    text-align: left;
    background-color: #f1f4f7;
} */

.completion-sa-table .fixed-td {
    position: -webkit-sticky;
    position: sticky;
    width: 250px;
    height: 50px;
    z-index: 2;
    left: 0;
    background-color: #fff;
}

.completion-sa-table .fixed-hd {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

.completion-sa-table .left-top-td {
    z-index: 3;
}

.completion-sa-table .scrollable-td {
    width: 120px;
    background-color: #fff;
}

.completion-sa-table .bg-striped {
    background-color: #f0f0f0;
}

.stacked-column-chart .apexcharts-legend-marker {
    width: 50px !important;
}

.scheduled-reports .react-select-scheduled__control {
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    color: #686868;
    color: var(--dove-shade-gray);
    border: 1px solid #b2b2b2;
    cursor: pointer;
}

.scheduled-reports .css-1pahdxg-control {
    box-shadow: none;
    border: 1px solid #ccc;
    color: #686868;
    color: var(--dove-shade-gray);
    border-radius: 0;
}

.scheduled-reports .react-select-scheduled__single-value {
    font-size: 14px;
    font-weight: 400;
    font-weight: var(--fw-regular);
    color: #686868;
    color: var(--dove-shade-gray);
}

.scheduled-reports .react-select-scheduled__option {
    background: #fdfcfc;
    color: #686868;
    color: var(--dove-shade-gray);
    font-size: 14px;
}

.scheduled-reports .react-select-scheduled__menu {
    font-size: 14px;
    color: #686868;
    color: var(--dove-shade-gray);
}

.scheduled-reports .react-select-scheduled__option {
    cursor: pointer;
}

.scheduled-reports .react-select-scheduled__indicator-separator {
    display: none !important;
}

.scheduled-reports .react-select-scheduled__indicators {
    background-color: #cdcdcd !important;
}

.scheduled-reports .react-select-scheduled__indicator {
    color: #686868 !important;
    padding: 9px 8px 8px 8px !important;
}

.scheduled-reports .report-name input[type="text"],
.scheduled-reports .report-name input[type="email"] {
    border-radius: 0px;
    border: 1px solid #b2b2b2;
    padding: 8px 10px;
}

.scheduled-reports .report-name input[type="text"]:focus-visible,
.scheduled-reports .report-name input[type="email"]:focus-visible {
    outline: none;
}

.scheduled-reports .report-name .btn-save {
    margin: 0 10px 0px 0px !important;
    color: #333333 !important;
    color: var(--dark-shade-gray) !important;
    background: #f77e0b !important;
    background: var(--orange-color) !important;
    border: 1px solid #333333 !important;
    border: 1px solid var(--dark-shade-gray) !important;
    padding: 3px 10px !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    height: 39px !important;
    width: 120px;
    -webkit-user-select: none;
            user-select: none;
}

.scheduled-reports .report-name .btn-save:hover {
    opacity: 0.8;
}

.scheduled-reports .report-name .btn-schedule {
    margin: 0 !important;
    color: #ffffff !important;
    background: #009345 !important;
    border: 1px solid #ffffff !important;
    padding: 3px 10px !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    height: 39px !important;
    width: 120px;
    -webkit-user-select: none;
            user-select: none;
}

.scheduled-reports .report-name .btn-schedule:hover {
    opacity: 0.8;
}

.archive-link {
    cursor: pointer;
    font-weight: 600;
    font-weight: var(--fw-semi-bold);
    color: #707070;
    text-decoration: none;
    -webkit-user-select: none;
            user-select: none;
}

.archive-link:hover {
    color: #707070;
    text-decoration: none;
}

.upload-sheet {
    height: calc(100vh - 335px);
    overflow-y: auto;
    background: #fff;
    padding: 10px;
}

/*============= Media Queries ================*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .setting-icons ul li {
        padding-right: 5px;
    }

    /*.content-box {*/
    /*    height: calc(100vh - -460px);*/
    /*}*/

    .tab-content ul li a {
        font-size: 9px;
    }

    .tab-content .nav-link {
        padding: 0.3rem 0.5rem;
        /* margin-right: 3px; */
    }

    .tab-content .btn-export {
        font-size: 12px;
    }

    /* .todo-list .rdt_TableBody {
        height: calc(100vh - 350px);
    } */

    .evidence-scroll i {
        right: -94px;
    }

    .checkout {
        padding: 50px 10px;
    }

    .btn-start-trial {
        font-size: 14px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 320px) {
    .frequency_modal .custom_modal_content_padding {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .gmp_role_modal_img {
        position: inherit;
    }
}

@media only screen and (min-width: 576px) {
    .frequency_modal .custom_modal_content_padding {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}


@media only screen and (max-width: 991px) {
    .setting-icons {
        min-height: 70px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    /*.home-nc {*/
    /*    height: calc(100vh - -235px);*/
    /*}*/

    .evidence-scroll i {
        right: -90px;
    }

    .kpi .custom-grid-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .frequency_modal .custom_modal_content_padding {
        padding-top: 63px;
        padding-bottom: 63px;
    }

    .browse-modal .modal-wd,
    .audit-roles-modal .modal-wd,
    .feedback-modal .modal-wd,
    .frequency_modal .modal-wd {
        max-width: 720px !important;
    }

    .pro-browse-modal .modal-wd {
        max-width: 740px !important;
    }

    .save-modal .modal-wd,
    .send-modal .modal-wd,
    .error-modal .modal-wd,
    .export-modal .modal-wd {
        max-width: 675px !important;
    }

    .modal-img {
        margin-top: 120px;
    }

    .audit-modal-img {
        position: relative;
        top: 10px;
    }

    .gmp_role_modal_img {
        position: absolute !important;
        left: 0;
        bottom: 0;
    }

    .setting-icons {
        min-height: 60px;
    }
}

/* @media only screen and (max-width: 991px) {
    /* .tab-content .nav-link {
        border-bottom: 0.1rem solid var(--dim-shade-gray) !important;
    } */

/* .nav-tabs {
        border-bottom: 1px solid #dee2e6 !important;
    } */
/* } */

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .kpi .custom-grid-container {
        grid-template-columns: repeat(7, 1fr);
    }

    .scheduled-reports .custom-grid-container {
        grid-template-columns: repeat(8, 1fr);
    }

    .setting-icons {
        min-height: 40px;
    }
}
